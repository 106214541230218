import * as React from "react";
import "./contact.css";
import { FaClock, FaEnvelope, FaMapMarkerAlt, FaPhoneAlt, FaRegEnvelope } from 'react-icons/fa';
import { BsFillInfoCircleFill } from "react-icons/bs";
import Layout from "../layouts/layout";
import favicon from "../images/favico.ico";
import { Helmet } from "react-helmet";


const ContactPage = () => {
    return (
        <>
            <Layout>
                <Helmet htmlAttributes={{
                    lang: 'fr-FR',
                }}>
                    <link rel="icon" type="image/x-icon" href={favicon} />
                    <title>Contactez-nous pour organiser votre voyage médical - Medica Tour</title>
                    <meta name="description" content="Notre équipe est à votre disposition pour répondre à toutes vos questions et vous aider à organiser votre voyage médical en toute sérénité." />
                    <meta name="robots" content="index, follow" />
                    <link data-react-helmet="true" rel="canonical" href="https://www.medica-tour.fr/contact/"></link>
                    <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10925153812"></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-10925153812');
      `}
    </script>
                </Helmet>

                <div className="container-fluid service pt-5 pb-5">
                    <div className="container intern-page-style">
                        <h1 className="service-title">
                            Contact
                        </h1>

                    </div>
                </div>

                <div className="container-fluid">

                    <div class="container pt-5 mb-5">
                        <div class="row">

                            <div class="col">
                                <div class="row pb-2 contact-icon">
                                    <FaClock size={70} />
                                </div>
                                <div class="row">
                                    <span class="text-center job-time">
                                        Lundi - Samedi 08:00 - 21:00 Dimanche - Fermé
                                    </span>
                                </div>
                            </div>

                            <div class="col">
                                <div class="row pb-2 contact-icon">
                                    <BsFillInfoCircleFill size={70} />
                                </div>
                                <div class="row">
                                    <span class="text-center job-time">
                                        <a href="tel:+216 99 236 037" className="link-contact">
                                            <span>
                                                <FaPhoneAlt /> +216 99 236 037
                                            </span>
                                        </a>
                                        <br />
                                        <a href="mailto:Contact@medica-tour.fr" className="link-contact">
                                            <span>
                                                <FaEnvelope /> Contact@medica-tour.fr
                                            </span>
                                        </a>
                                    </span>
                                </div>
                            </div>


                            <div class="col">
                                <div class="row pb-2 contact-icon">
                                    <FaMapMarkerAlt size={70} />
                                </div>
                                <div class="row">
                                    <span class="text-center job-time">
                                        145, RES MAYA "B" - H NOUIRA, BORJ LOUZIR 2073
                                    </span>
                                </div>
                            </div>


                        </div>
                    </div>


                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2257.0043282857628!2d10.197224299357183!3d36.869144308510364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2cbd190eb6337%3A0x247fbf54781134c3!2sMedicaTour!5e0!3m2!1sen!2stn!4v1667302644348!5m2!1sen!2stn"
                        allowfullscreen="true"
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>



                </div>

            </Layout>
        </>
    )
}

export default ContactPage
